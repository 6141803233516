import React from 'react';
import { Link } from 'react-scroll';

import './About.scss';

const About = () => {
  return (
    <section className="about section bd-container" name="about">
      <h1 className="section__title">Qui sommes-nous ?</h1>
      <div className="about__container">
        <div className="about__img">
          <img
            data-aos="fade-right"
            data-aos-offset="500"
            src="https://res.cloudinary.com/fk-web-agency/image/upload/v1626285002/chrono-pieces-auto/Home-About-Contact/profil_pefl7d.jpg"
            alt="à propos chrono pièces auto"
            loading="lazy"
          />
        </div>
        <div data-aos="fade-left" data-aos-offset="500" className="about__text">
          <p>
            Depuis qu’elle a été créée en 2009, la société Chrono pièce auto à
            su se développer en tant que <strong> leader dans la région</strong>, dans la vente de pièces
            auto et moto. Nous vous proposons <strong>un large choix de produits de
            qualité et au meilleur prix.</strong> Que vous recherchiez une pièce neuve ou
            d'occasion et ce pour n'importe qu'elle modèl de voiture, moto, camion et autre, notre
            large gamme de pièces détachés saura répondre à votre demande. Vous pourrez également trouver tout
            ce dont vous avez besoin pour remettre votre véhicule en état : <strong>des
            pièces auto indispensables </strong>comme les freins, les filtres, la
            suspension et l’embrayage, les accessoires autos, motos, quad et
            camion. <br/> Nos efforts nous permettent de vous garantir un excellent
            service, rapide et de qualité, nous avons à ce jour pu satisfaire
            les exigences de milliers de clients, grâce à des prix imbattables et une qualité infaillible ! N'hésitez pas à {' '}
            <Link id="link" to="contact">
              {' '}
              nous contacter{' '}
            </Link>{' '}
            si vous souhaitez avoir de plus amples information.
            <br />
            <br />
            Chez chronos pièces auto-moto il est également possible de choisir
            des pièces entre différentes marque; Vous aurez le choix parmi les
            meilleures d'entre elles, telles que : BOSCH, Valeo, Castrol, Total,
            WYNNS SKF, TRW, Monroe, Contitech, Luk, Purflux, et bien d’autres.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
