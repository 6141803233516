import Nav from './Nav/Nav';
import Home from './Home/Home';
import Partenaire from './Partenaire/Partenaire';
import Product from './Product/Product';
import Promo from './Promo/Promo';
import About from './About/About';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

const Page = ({ changeTheme, setChangeTheme }) => {
  return (
    <>
      <Nav changeTheme={changeTheme} setChangeTheme={setChangeTheme}/>
      <section
        style={{
          position: 'absolute',
          top: '15%',
          width: '100%',
        }}
        className="container">
        <Home />
        <Partenaire />
        <Product />
        <Promo />
        <About />
        <Contact />
        <Footer changeTheme={changeTheme} />
      </section>
    </>
  );
};

export default Page;
