import image from './image.json';

import './Partenaire.scss';
const Partenaire = () => {
  return (
    <section className="partenaire section bd-container">
      <h1 className="section__title">Nos Partenaires</h1>
      <div className="slider">
        <div className="slide-track">
          {image.map((el, index) => {
            return (
              <div key={index} className="slide">
                <img src={el.image} height="100" width="250" alt="" />
              </div>
            );
          })}
           {image.map((el, index) => {
            return (
              <div key={index} className="slide">
                <img src={el.image} height="100" width="250" alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Partenaire;
