import React from 'react'
import './Home.scss'

const Home = () => {
    return (
            <section className="home" name="home">
                <div  className="home__banner">
                <img rel="preload" as="image" src="https://res.cloudinary.com/fk-web-agency/image/upload/v1626285083/chrono-pieces-auto/Home-About-Contact/banner_tufmsn.jpg" alt="bannière chrono piéce auto" />
                </div>
                <h1 className="home__title"> Chrono pièces auto-moto</h1>
            </section>
    )
}

export default Home
