import React from 'react';

const Mention = () => {
  return (
    <>
      <h2>Informations légales</h2>
      <p>
        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l'économie numérique, il est précisé aux utilisateurs du
        site <a href="//www.chronopiecesauto.fr/">www.chronopiecesauto.fr</a>{' '}
        l'identité des différents intervenants dans le cadre de sa réalisation
        et de son suivi :
      </p>
      <p>
        <strong>Propriétaire</strong> : Chrono Pièces Auto – SARL 50003471500010
        – 24 Avenue du Président Franklin Roosevelt, 78200 Mantes - la - Jolie
        <br />
        <strong>Créateur</strong> :{' '}
        <a target="blank" href="https://www.fkwebagency.com">
          FK Web Agency
        </a>
        <br />
        <strong>Responsable publication</strong> : FK Web Agency –
        contact@fkwebagency.com
        <br />
        Le responsable publication est une personne physique ou une personne
        morale.
        <br />
        <strong>Webmaster</strong> : FK Web Agency – contact@fkwebagency.com
        <br />
        <strong>Hébergeur</strong> : Vercel – 340 S Lemon Ave #4133 Walnut, CA
        91789
        <br />
        Crédits : Lead Développeur: Fatiha Elkaroutti Designer: Fatiha
        Elkaroutti Développeur: Mamadou Faye Seck
        <br />
        Le modèle de mentions légales est offert par{' '}
        <a target="blank" href="https://www.subdelirium.com/">
          Subdelirium.com
        </a>
      </p>
    </>
  );
};

export default Mention;
