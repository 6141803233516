import SimpleMap from '../SimpleMap';

const Maps = () => {
  return (
    <>
      <div className="bd-grid">
        <div className="adress">
          <h3> Nos Coordonées </h3>{' '}
          <address>
            Adresse:{' '}
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.google.com/maps/dir//24+Avenue+du+Pr%C3%A9sident+Franklin+Roosevelt,+78200+Mantes-la-Jolie/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x47e6bfab11cef2b5:0xba0990ffb994933b?sa=X&ved=2ahUKEwi8gbLrgNbxAhUG4BoKHeUcC_oQwwUwAHoECAoQAw">
              {' '}
              24 Avenue du Président Franklin Roosevelt, 78200 Mantes - la -
              Jolie
            </a>{' '}
          </address>{' '}
          <p>
            Téléphone: <a href="tel:01 30 92 15 30"> 01 30 92 15 30 </a>{' '}
          </p>{' '}
        </div>
        <div style={{ marginBottom: '2rem' }} className="timetable">
          <h3> Nos Horaires </h3>{' '}
          <ul>
            <li>
              {' '}
              Du Lundi au Vendredi: <span>
                09h00/12 h30 - 13h30/19 h00{' '}
              </span>{' '}
            </li>{' '}
            <li> Samedi: 10h00/15h00 </li> <li> Dimanche: Fermé </li>{' '}
          </ul>{' '}
        </div>{' '}
      </div>
      <SimpleMap />
    </>
  );
};

export default Maps;
