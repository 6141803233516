import React from 'react';

const SubContainer = () => {
  const changeContent = () =>
    document.querySelector('.contact__container').classList.toggle('switch');

  return (
    <div
      data-aos="fade-left"
      data-aos-offset="500"
      className="contact__sub-contaire">
      <div className="contact__sub-contaire--img contact-img">
        <div className="contact__sub-contaire--text m--up">
          <h2>Coordonnée</h2>
          <address>
            Adresse: 24 Avenue du Président Franklin Roosevelt, 78200
            Mantes-la-Jolie
          </address>
          <p>
            Téléphone : <a href="tel:01 30 92 15 30"> 01 30 92 15 30</a>
          </p>
        </div>
        <div className="contact__sub-contaire--text m--in">
          <h2>Besoin d'information?</h2>
          <p>
            Si vous besoin d'informations ou d'un devis n'hésitez pas nous
            envoyer un message
          </p>
        </div>
        <div onClick={changeContent} className="contact__sub-contaire--btn">
          <span className="m--up">m'y rendre</span>
          <span className="m--in">contacter</span>
        </div>
      </div>
    </div>
  );
};

export default SubContainer;
