import React from 'react';
import data from './data.json';
import './Products.scss';

const Product = () => {
  return (
    <section className="product section bd-container" name="product">
      <h1 className="section__title">Nos Produits</h1>
      <p className="product__accroche">

      Toutes les pièces détachées disponibles chez Chrono Pièces Auto sont homologuées et respectent les standards européens de qualité et sécurité : en achetant chez nous, vous aurez la certitude d’acheter des produits fiables et certifiés, fabriqués pour durer. Nous vous garantissons aussi d’importantes économies sur votre budget auto. En achetant nos pièces directement chez les meilleurs équipementiers, nous vous proposons régulièrement de nombreuses promotions et offres spéciales, en plus de prix allant jusqu'à -70% sur le tarif public.<br/><br/>

      <h4>Pourquoi nous faire confiance ? </h4> 
      Fiabilité, qualité, rapidité. Depuis 2009, Chrono pièces auto n’a eu qu’une seule et même priorité : la satisfaction de ses clients.
      En plus de la qualité de nos produits et des prix bas toute l'année, nos pièces sont disponible immédiatement et/ou sur commande en 24h.
<br/>
      Notre équipe est à votre service, afin que vous profitiez pleinement de votre véhicule en toute sérénité !
      </p>
      <div
        data-aos="fade-down"
        data-aos-offset="500"
        className="product__container">
        {data.map((el) => {
          return (
            <div key={el.id} className="product__card">
              <div className="product__card--img">
                <img
                  className="product__card--img"
                  src={el.image}
                  alt={`${el.product} chez Chrono pièces auto`}
                  loading="lazy"
                />
              </div>
              <h3 className="product__card--title">{el.product}</h3>
              <div className="product__card--description ">
                <p className="product__card--description-text">{el.category}</p>
                <i className="product__card--description-price">
                  À partir de <span>{el.price}</span>{' '}
                </i>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Product;
