import { Link } from 'react-router-dom'
import './Error.scss';

const Error = () => {
    return (
        <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>4<span></span>4</h1>
			</div>
			<h2>Oops! Page introuvable</h2>
			<p>Désolé mais la page que vous recherchez n'existe pas, elle a été supprimée. le nom à changé ou est temporairement indisponible</p>
			<Link className="button" to="/">retour à la page d'accueil</Link>
		</div>
	</div>
    )
}

export default Error
