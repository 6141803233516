/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { ElfsightWidget } from 'react-elfsight-widget';
import Button from '../Button/Button';
import './Nav.scss';
import 'boxicons';
const Nav = ({ changeTheme, setChangeTheme }) => {
  const [toggle, setToggle] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.pageYOffset);
  const [day, setDay] = useState();
  const [horaire, setHoraire] = useState('09h00-12h30 / 13h30-19h00');
  const [opening, setOpening] = useState('Ouvert');

  useEffect(() => {
    let body = document.body;

    changeTheme
      ? body.classList.add('dark-theme')
      : body.classList.remove('dark-theme');

    /* Day change based on day */
    let date = new Date();
    let dayList = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ];
    let hour = date.getHours();
    let minute = date.getMinutes();

    /* CONDITION FOR OPENING TIME */
    setDay(dayList[date.getDay()]);

    if (day === 'Dimanche') setHoraire('FERMÉ');
    if (day === 'Samedi') setHoraire('10h00-15h00');

    /* CONDITION HOURS CLOSING */
    // eslint-disable-next-line no-mixed-operators
    if (
      (hour === 12 && minute >= 30) ||
      hour >= 19 ||
      (day === 'Samedi' && hour >= 15) ||
      day === 'Dimanche' ||
      hour < 9
    )
      setOpening('FERMÉ');
    /* CONDITION HOURS OPENING */
    // eslint-disable-next-line no-mixed-operators
    if ((hour === 13 && minute === 30) || (day === 'Samedi' && hour <= 10))
      setOpening('Ouvert');

    const changeWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, [changeTheme, day]);

  const display = () => setToggle(!toggle);

  /*
CHANGE THEME
 */

  // We obtain the current theme that the interface has by validating the dark-theme class
  const getCurrentTheme = () => (changeTheme ? 'light' : 'dark');

  // Function onclick
  const theme = () => {
    setChangeTheme(!changeTheme);
    //If user refuse cookie consent
    {
      document.cookie &&
        localStorage.setItem('selected-theme', getCurrentTheme());
    }

    // We save the theme and the current icon that the user chose
  };

  /*======= SCROLL TOP  =======*/
  const changeHeight = () => {
    setHeight(window.pageYOffset);
  };
  window.addEventListener('scroll', changeHeight);
  if (height <= 250 && toggle)
    document.getElementsByClassName('nav__menu').className =
      'show-menu-top nav__menu';
  //nav__menu
  return (
    <>
      {height >= 300 && (
        <div className="scrolltop" onClick={() => scroll.scrollTo('home')}>
          {changeTheme ? (
            <box-icon color="#fff" type="solid" name="up-arrow-alt" />
          ) : (
            <box-icon type="solid" name="up-arrow-alt" />
          )}
        </div>
      )}

      <header className="l-header" name="header">
        <div className={height >= 200 ? 'banner-hidden' : 'banner'}>
          <Button />
          <div className="banner__logo">
            <img
              style={{ width: '200px' }}
              height="150"
              src="https://res.cloudinary.com/fk-web-agency/image/upload/v1626285060/chrono-pieces-auto/Home-About-Contact/Chrono_pegbef.png"
              alt=""
            />
          </div>
          <div className="banner__timetable">
            <p style={{ textAlign: 'center' }}>Votre magasin est </p>
            <p
              className={
                opening === 'Ouvert'
                  ? 'banner__timetable--opening green'
                  : 'banner__timetable--opening'
              }>
              {opening}
            </p>
            <p className="banner__timetable--day">
              {day}{' '}
              <span className="banner__timetable--horaire">{horaire}</span>
            </p>
          </div>
        </div>
        <nav
          className={
            height <= 200 ? 'nav bd-container nav-2' : 'nav bd-container'
          }>
          <Link to="home" className="nav__logo">
            Chrono Piéces
          </Link>

          <div
            className={`${toggle ? 'show-menu nav__menu' : 'nav__menu'} ${
              height <= 250 && toggle ? 'nav__menu show-menu-top' : 'nav__menu'
            }`}>
            <ul className="nav__list">
              <li className="nav__item">
                <Link
                  to="home"
                  className="nav__link"
                  activeClass="active-link"
                  spy={true}
                  smooth={true}
                  duration={100}>
                  Accueil
                </Link>
              </li>

              <li className="nav__item">
                <Link
                  to="product"
                  className="nav__link"
                  activeClass="active-link"
                  spy={true}
                  smooth={true}
                  duration={100}>
                  Nos produits
                </Link>
              </li>

              <li className="nav__item">
                <Link
                  to="promo"
                  className="nav__link"
                  activeClass="active-link"
                  spy={true}
                  smooth={true}
                  duration={100}>
                  Promotions
                </Link>
              </li>

              <li className="nav__item">
                <Link
                  to="contact"
                  className="nav__link"
                  activeClass="active-link"
                  spy={true}
                  smooth={true}
                  duration={100}>
                  Contact
                </Link>
              </li>

              <li className="change-theme" onClick={theme}>
                {changeTheme ? (
                  <box-icon color="#F3F1F1" size="md" name="toggle-right" />
                ) : (
                  <box-icon color="orange" size="md" name="toggle-left" />
                )}
              </li>
            </ul>
          </div>

          {width <= 768 && (
            <span onClick={display}>
              {changeTheme ? (
                <box-icon color="#F3F1F1" name="grid-alt" />
              ) : (
                <box-icon name="grid-alt" />
              )}
            </span>
          )}
        </nav>
      </header>
      {width <= 768 && height <= 4700 && height >= 120 && (
        <ElfsightWidget widgetID="7d191a99-1c89-4b95-9357-cd9982ae4cf1" />
      )}
    </>
  );
};

export default Nav;
