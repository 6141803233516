import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

import DOMPurify from 'dompurify';
const Form = () => {
  const [value, setValue] = useState();
  /*====== VERIFICCATION HUMAN =======*/
  const onChange = (value) => setValue(value);
  /*====== XSS PREVENT =======*/
  const handleChange = (e) => {
    document.getElementById(e.target.id).value = DOMPurify.sanitize(
      e.target.value
    );
  };

  /* SEND EMAIL */
  const alert = (icon, title, message) => {
    return Swal.fire({
      icon: icon,
      title: title,
      text: message,
      showConfirmButton: false,
      timer: 3500,
    });
  };

  function sendEmail(e) {
    e.preventDefault();

    if (!value)
      return alert(
        'warning',
        'Oops...',
        'Merci de confirmer que vous étes un humain'
      );
    emailjs
      .sendForm(
        'gmail',
        'template_chrono',
        e.target,
        'user_AnRKPe9TQgc2nQIoJ6zy5'
      )
      .then(
        (result) =>
          alert(
            'success',
            'Message envoyé',
            'Merci de nous avoir contacter, nous revenons vers vous dans les plus bref délai!'
          ),
        (error) =>
          alert(
            'error',
            'Oops...',
            'Merci de nous contacter par email ou par téléphone'
          )
      );
  }

  return (
    <form className="contact__form" onSubmit={sendEmail}>
      <div
        data-aos="fade-left"
        data-aos-offset="500"
        data-aos-delay="650"
        className="contact__form--radio">
        <div>
          <input type="radio" name="genre" value="Madame" id="genre" />
          <label htmlFor="genre">Madame</label>
        </div>
        <div>
          <input type="radio" name="genre" value="Monsieur" id="genre" />
          <label htmlFor="genre">Monsieur</label>
        </div>
      </div>

      <div
        data-aos="fade-left"
        data-aos-offset="500"
        data-aos-delay="750"
        className="contact__form--select">
        <label htmlFor="profession">Vous étes:</label>
        <select name="profession" id="profession">
          <option value="Un particulier">Un particulier</option>
          <option value="Une entreprise ou une collectivité ">
            Une entreprise ou une collectivité
          </option>
          <option value="Une association">Une association</option>
          <option value="Un professionnel de la réparation auto/moto">
            Un professionnel de la réparation auto/moto
          </option>
        </select>
      </div>

      <div className="contact__form--input bd-grid">
        <input
          data-aos="fade-left"
          data-aos-offset="500"
          data-aos-delay="800"
          onChange={handleChange}
          type="text"
          name="nom"
          id="name"
          placeholder="Votre nom"
          required
        />
        <input
          data-aos="fade-left"
          data-aos-offset="500"
          data-aos-delay="850"
          onChange={handleChange}
          type="text"
          id="prenom"
          name="prenom"
          placeholder="Votre prénom"
          required
        />
        <input
          data-aos="fade-left"
          data-aos-offset="500"
          data-aos-delay="900"
          onChange={handleChange}
          type="email"
          id="email"
          name="email"
          placeholder="Entrez votre email"
          required
        />
        <input
          data-aos="fade-left"
          data-aos-offset="500"
          data-aos-delay="950"
          onChange={handleChange}
          type="tel"
          id="numero"
          name="numero"
          placeholder="Saissiez votre numéro de téléphone"
          required
        />
        <input
          data-aos="fade-left"
          data-aos-offset="500"
          data-aos-delay="1000"
          onChange={handleChange}
          type="text"
          id="immatriculation"
          name="immatriculation"
          placeholder="Entrez votre numéro SIREN ou SIRET"
        />

        <textarea
          data-aos="fade-left"
          data-aos-offset="500"
          data-aos-delay="1250"
          onChange={handleChange}
          id="message"
          name="message"
          placeholder="Ecrivez votre message"
          required></textarea>
      </div>
      <div
        data-aos="fade-left"
        data-aos-offset="500"
        data-aos-delay="1140"
        className="contact__verify">
        <button className="button" type="submit" value="Envoyer">
          Envoyer{' '}
        </button>
        <ReCAPTCHA
          sitekey="6LdljX8bAAAAAAEOucue9ukWBz__2BYL7Jkebbul"
          onChange={onChange}
        />
      </div>
    </form>
  );
};

export default Form;
