import { Link } from 'react-scroll';

const Button = () => {
    return (
        <div className="banner__buttons">
        <Link  className="button" to="contact">
          Nous contacter
        </Link>
        <a className="button" href="tel:0130921530">
          0130921530
        </a>
      </div>
    )
}

export default Button
