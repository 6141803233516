import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Aos from 'aos';
import 'aos/dist/aos.css';

import Error from './Components/Error/Error';
import Page from './Components/Page';

function App() {
  const [changeTheme, setChangeTheme] = useState(false);
  useEffect(() => {
    const darkTheme = 'dark-theme';

  // Previously selected topic (if user selected)
  const selectedTheme = localStorage.getItem('selected-theme');

  if (selectedTheme) {
    // If the validation is fulfilled, we ask what the issue was to know if we activated or deactivated the dark
    document.body.classList[selectedTheme === 'dark' ? 'add' : 'remove'](
      darkTheme
    );
  }

  }, []);
  Aos.init({
    duration: 2000,
  });

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
          <Page changeTheme={changeTheme} setChangeTheme={setChangeTheme} />
          </Route>
          <Route component={Error} />
        </Switch>
      </Router>{' '}
      {!document.cookie && (
        <CookieConsent
          debug={true}
          enableDeclineButton={true}
          style={{
            color: 'white',
          }}
          buttonStyle={{
            background: 'green',
            color: 'white',
          }}
          buttonText="D'accord"
          declineButtonText="Je refuse">
          Pour rendre votre navigation agréable, nous utilisons des cookies🍪{' '}
        </CookieConsent>
      )}{' '}
    </div>
  );
}

export default App;
