import Button from '../Button/Button';
/*====== ici =======*/
import './Promo.scss';
const Promo = () => {
    return (
        <section className="promo section bd-container" name="promo">
            <h1 className="section__title">Nos Promotions</h1>
            <div data-aos="fade-down" data-aos-offset="500">
          
            <p className="promo__text">Retrouvez nous en magasin et profité de toutes nos promotions. Nous, nous assurons également de vous conseiller <strong>les meilleurs garagiste </strong> pour vos répations automobile, et cela toujours au <strong>meilleur prix. </strong> N'hesitez pas à nous contacter pour de plus amples informations, nous sommes joignable par téléphone et par email aux horaires d'ouverture.</p>
            {/* <h4 className="promo__shop">Chrono Pièces Auto</h4> */}
            <Button/>
            </div>
        </section>
    )
}

export default Promo
