import Form from './Form';
import SubContainer from './SubContainer';
import Maps from './Maps';
import './Contact.scss';
const Contact = () => {
  return (
    <section className="contact section bd-container" name="contact">
      <h1 className="section__title"> Contactez - nous </h1>{' '}
      <div className="contact__container">
        <SubContainer />
        <div className="contact__container--item contact-form">
          <h2> Nous contacter, </h2> <Form />
        </div>{' '}
        <div className="contact__container--item maps">
          <h2> S 'y rendre,</h2> <Maps />
        </div>
      </div>{' '}
    </section>
  );
};

export default Contact;
