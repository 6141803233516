// TODO ajouter l'email pour la partir contact

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './Footer.scss';
import 'boxicons';
import data from './data.json';
import About from '../About/About';
import Mention from './Mention';
import Cgu from './Cgu';

const Footer = ({ changeTheme }) => {
  const [open, setOpen] = useState(false);
  const [dataModal, setDataModal] = useState();
  const onOpenModal = (id) => {
    const dataLink = data.filter((el) => el.id === id);
    setDataModal(dataLink);
    console.log(dataLink);

    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  return (
    <footer className="footer section">
      <div className="footer__container bd-container bd-grid">
        <div
          data-aos="fade-down"
          data-aos-delay="1500"
          className="footer__content">
          <h3 className="footer__title">
            <Link to="/">Chrono Piéces Auto</Link>
          </h3>
          <p className="footer__description">
            Vos pièces auto / moto <br/>  à petit prix.
          </p>
        </div>

        <div
          data-aos="fade-down"
          data-aos-delay="1500"
          className="footer__content">
          <h3 className="footer__title">Liens utiles</h3>
          <ul>
            {data.slice(0, 2).map((el) => {
              return (
                <li key={el.id} onClick={() => onOpenModal(el.id)} className="footer__link">
                  {el.item}
                </li>
              );
            })}
          </ul>
        </div>

        <div
          data-aos="fade-down"
          data-aos-delay="1500"
          className="footer__content">
          <h3 className="footer__title">Qui-sommes nous ?</h3>
          <ul>
            {data.slice(2, 4).map((el) => {
              return (
                <li key={el.id} onClick={() => onOpenModal(el.id)} className="footer__link">
                  {el.item}
                </li>
              );
            })}
            <li>
              <a href="mailto:chronopiecesauto@orange.fr" className="footer__link">
                contact
              </a>
            </li>
          </ul>
        </div>

        <p className="footer__copy">
          Réalisé par{' '}
          <a
            className="footer__link"
            target="_bank" rel="noopener noreferrer"
            href="https://www.fkwebagency.com">
            {' '}
            <span>FK Web Agency</span>{' '}
          </a>{' '}
          <br /> &#169; 2021 Chrono Pièces Auto
        </p>
      </div>
      {
          dataModal &&(

      <Modal open={open} onClose={onCloseModal} center>
        {dataModal[0].id === 3 ? <About/> :  <p>{dataModal[0].element} </p>}
        {dataModal[0].id === 2 ? <Mention/> :  <p>{dataModal[0].element} </p>}
        {dataModal[0].id === 1 ? <Cgu/> :  <p>{dataModal[0].element} </p>}
      </Modal>
          )
      }
    </footer>
  );
};




export default Footer;
